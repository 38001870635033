.option {
    &_container-default {
        @apply w-full grid grid-cols-1 md:grid-cols-3 justify-center gap-y-4 md:gap-x-4 place-content-center;
    }
    &_container-custom {
        @apply grid md:flex gap-y-4 md:gap-x-4 justify-center;
    }
    &_cards {
        &--custom {
            @apply md:w-[446px];
            &:hover {
                span {
                    @apply font-demi text-green-dark;
                }
            }
        }
        &:hover {
            span {
                @apply font-demi text-green-dark;
            }
        }
    }
}
