@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.home {
    > * {
        scrollbar-color: $green-dark;
    }
}
.hero {
    [data-heading] {
        > * {
            font-size: $font-huge;
            line-height: 1;
            @include between-lg-xl {
                font-size: $font-huge;
            }
            @include xl-up {
                font-size: $font-display1;
            }
        }
    }

    [data-hero-cta-tiles] {
        svg {
            > * {
                fill: $green;
            }
        }
    }
    overflow: hidden;
    .hero-tiles {
        width: 105%;
        animation: nudge 2s ease-in-out 1s forwards;
        pointer-events: none;
        @include md-up {
            width: 100%;
            animation: none;
            pointer-events: auto;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    .hero-tiles--carousel {
        @apply flex mb-8 pb-4 space-x-4 overflow-x-auto overflow-y-hidden snap-x snap-mandatory pl-4 pr-4;
        @apply md:mb-0 md:grid md:w-full md:grid-cols-3 md:grid-rows-2 md:gap-x-4 md:gap-y-4 md:space-x-0 md:overflow-visible md:px-0;
        @apply xl:flex xl:overflow-x-auto xl:overflow-y-hidden  xl:px-4;
        @include ipad-landscape-only {
            @apply lg:space-x-4;
        }
    }
    .hero-tiles--variant-a {
        @apply flex mb-8 pb-4 space-x-4 overflow-x-auto overflow-y-hidden snap-x snap-mandatory pl-4 pr-4;
        @apply lg:mb-0 lg:grid lg:w-full lg:grid-cols-4 lg:grid-rows-2 lg:gap-x-4 lg:gap-y-4 lg:space-x-0 lg:overflow-visible lg:px-0;

        [data-cta-tile] {
            svg {
                display: none;
            }
            @include xxl-up {
                svg {
                    display: block;
                }
            }
            @include lg-up {
                header {
                    aspect-ratio: 160 / 123;
                }
            }
            @include between-lg-xl {
                section {
                    height: 3rem;
                    padding: 0.75rem;
                    a,
                    span {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}

.service--link {
    &:hover {
        svg {
            background-color: $green-light;
        }
    }
}

@keyframes nudge {
    50% {
        width: 105%;
        transform: translateX(-5%); // nudge distance
    }
    100% {
        width: 100%;
        transform: translateX(0);
        padding-right: 1rem;
        @apply snap-x snap-mandatory;
        pointer-events: auto;
    }
}

