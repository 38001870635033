.section-title {
  @apply pb-6 max-w-[14rem] text-center mx-auto;
  @apply md:max-w-none md:pb-14;
}

.card-content-wrapper {
  @apply flex flex-col justify-between h-full px-4 pt-3 pb-5;
}

.card-header {
  @apply text-regular font-bold leading-normal tracking-normal mb-0;
  @apply md:leading-snug
}

.card-rich-text {
  ul {
    @apply ml-5 list-outside list-disc;
  }
  li {
    @apply text-sm leading-normal tracking-tight;
    @apply md:text-base md:leading-6;
  }
  p {
    @apply text-sm leading-normal tracking-tight mb-0;
    @apply md:text-base md:leading-6;
  }
  p + p {
    @apply mt-1;
  }
}

.card-link {
  @apply text-sm leading-normal tracking-normal underline font-bold;
}
