@import '../../src/scss/utilities/breakpoints';
@import '../../src/scss/variables/index';

.card-sand {
    background: $sand-light;
    border-radius: 1rem;
    padding: 1.5rem;
    margin: 1.5rem 1rem 1.5rem;
}

.card-sand-title {
    margin-bottom: 0.5rem;
}
