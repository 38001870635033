.wrapper {
    @apply container rounded-lg border-2 bg-white p-4 max-w-3xl mx-auto lg:p-8;
    [data-heading] {
        > * {
            @apply text-lg sm:text-xl md:text-xxl;
        }
    }
}

.top {
    @apply w-full flex flex-nowrap justify-between items-center mb-4;
}

.badge {
    @apply inline-flex px-2 py-1 items-center rounded-full uppercase tracking-widest font-demi text-xs md:text-base bg-green-light text-center;
}

.tabs {
    @apply flex flex-nowrap;
    .tab {
        @apply border-b-2 py-2 px-4 sm:px-8 md:px-16 xl:px-12 border-grey-300;
    }
    [data-tab-active='true'] {
        @apply border-green-dark font-demi;
    }
}

.product {
    @apply w-full flex flex-wrap lg:flex-nowrap lg:gap-4 items-center;
}
.image {
    @apply w-full md:w-1/2;
    img {
        @apply py-2 max-h-[120px] md:max-h-[300px] w-auto mx-auto;
    }
    .active-ingredients {
        @apply block md:hidden mb-0;
    }
}
.active-ingredients {
    @apply mb-4 font-demi w-full text-center text-regular;
}
.content {
    @apply w-full md:w-1/2 pt-4 md:pt-8 sm:px-4;
    .usp {
        @apply text-center w-full mb-4;
        span {
            @apply border border-green-dark rounded-full py-2 pl-2 pr-4 font-demi text-xs inline-flex flex-nowrap items-center mx-auto mb-4;
            svg {
                @apply rounded-full border border-green-dark w-2 h-2 p-1 mr-2;
            }
        }
    }
}

.slices {
    @apply mb-8;
}

.bottom {
    @apply flex flex-wrap w-full justify-center sm:flex-nowrap sm:gap-4 items-center md:block;
    .price {
        @apply font-demi text-md mb-4 md:mb-0;
    }
}

.references {
    @apply mt-6 md:mt-8;
    p {
        @apply text-grey-400 text-[0.625rem] md:text-xs;
    }
}
