@import '../../src/scss/utilities/breakpoints';
@import '../../src/scss/variables';

.image-content__container {
    @apply flex;
}

.image-content__img {
    @apply relative w-full h-60;
    @apply md:w-1/2 md:h-auto;
    @apply lg:mb-0 lg:w-2/3;

    img {
        @apply object-cover h-auto;
        @apply md:relative #{!important};
        @apply lg:w-2/3;
    }
}

.image-content__content {
    @apply flex flex-col items-start justify-center w-full p-6;
    @apply md:w-1/2 md:py-8 md:px-12;
    @apply lg:w-1/3 lg:py-12 lg:px-20;
}