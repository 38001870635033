.items {
    @apply flex flex-wrap justify-center mt-8;
    @apply md:flex-nowrap md:space-x-8;
    @apply lg:mt-14;
    @apply xl:space-x-12;
}

.item-image-wrapper {
    @apply block mb-4 md:mb-6 text-center max-w-[5rem] mx-auto;
    @apply sm:max-w-[8.75rem];
    @apply md:max-w-[10rem];
    @apply lg:max-w-[11.25rem];
}
