@import 'src/scss/utilities/breakpoints';
@import 'src/scss/variables/index';

.wrapper {
    @apply container;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include lg-up {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    :global(.hs-form) {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;

        @include lg-up {
            max-width: 42rem;
        }

        :global(.hs-form-field) {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            padding-bottom: 1.5rem;

            label {
                padding-left: 1rem;
                font-family: $font-family-bold;
                font-size: $font-small;
                color: $grey-400;
            }

            :global(.hs-form-required) {
                padding-left: 0.25rem;
                color: $red-error;
            }

            :global(.hs-error-msg) {
                position: absolute;
                bottom: 0;
                color: $red-error;
                font-family: $font-family-main;
            }

            :global(.input) {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.5rem;
                border-radius: 0.5rem;
                padding: 0 1rem;
                outline: 1px solid $grey-300;
                background-color: $white;
                transition: all 0.2s ease-in-out;

                &:hover,
                &:focus-within,
                &[data-has-error='true'] {
                    outline-width: 2px;
                }

                &:hover {
                    background-color: $grey-100;
                }

                &:focus-within {
                    outline-color: $blue;
                }

                &[data-has-error='true'] {
                    outline-color: $red-error;
                }

                &[data-disabled='true'] {
                    outline: none;
                    background-color: $grey-100;
                    cursor: not-allowed;
                }

                &[data-has-value='false'] {
                    color: $grey-300;
                }

                input,
                select {
                    padding: 0.75rem 0;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    flex: 1;
                }

                input {
                    // Hide number input arrows
                    /* Chrome, Safari, Edge, Opera */
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    &[type='number'] {
                        -moz-appearance: textfield;
                    }
                }

                select {
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    z-index: 1;
                }

                span {
                    color: $grey-400;
                    font-family: $font-family-bold;
                    padding: 0.75rem 1rem 0.75rem 0;
                    margin-right: 0.5rem;
                    border-right: 1px solid $grey-300;
                }
            }
        }

        :global(.hs-button) {
            background-color: $orange;

            &:hover {
                background-color: $orange-dark;
            }

            border: 1px solid transparent;
            border-radius: 0.5rem;
            box-shadow: none;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            font-family: MaisonNeue-Demi;
            font-size: 1rem;
            line-height: 100%;
            overflow: hidden;
            padding: 1rem 1.5rem;
            position: relative;
            text-align: center;
            transition: all 0.2s ease-in-out;
            white-space: nowrap;
        }
    }
}
