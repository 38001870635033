@import '../../scss/variables';
@import '../../scss/utilities/breakpoints';

// Default heading classes

.wrapper {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        font-family: $font-family-headings;
        line-height: 1.25;
        color: inherit;
        @apply tracking-tight;
    }

    h1,
    .h1 {
        font-size: clamp(2.25rem, 3.5vw, $font-size-h1);
        letter-spacing: -1px;
        line-height: 1.125;
        @include ipad-portrait-only {
            font-size: 2.25rem;
        }
        @include ipad-landscape-only {
            font-size: 2.25rem;
        }
    }

    h2,
    .h2 {
        font-size: clamp(1.75rem, 3vw, $font-size-h2);
        letter-spacing: -1px;
        @include ipad-portrait-only {
            font-size: 2rem;
        }
        @include ipad-landscape-only {
            font-size: 2rem;
        }
    }

    h3,
    .h3 {
        font-size: clamp($font-medium, 2vw, $font-size-h3);
        letter-spacing: -1px;
        @include ipad-portrait-only {
            font-size: $font-medium;
        }
        @include ipad-landscape-only {
            font-size: $font-medium;
        }
    }

    h4,
    .h4 {
        font-size: clamp($font-regular, 1.5vw, $font-size-h4);
        @include ipad-portrait-only {
            font-size: $font-regular;
        }
        @include ipad-landscape-only {
            font-size: $font-regular;
        }
    }

    h5,
    .h5 {
        font-size: clamp($font-small, 1vw, $font-size-h5);
        @include ipad-portrait-only {
            font-size: $font-small;
        }
        @include ipad-landscape-only {
            font-size: $font-small;
        }
    }
    p {
        margin-bottom: 0;
    }
}

// Helper classes

@mixin font-helpers {
    @each $name, $size in $font-helper-map {
        &-#{$name} {
            font-size: $size;
        }
    }
}

// Helper render

.font {
    @include font-helpers();
}

.item {
    color: inherit;
}
// Heading Styles

.wrapper {
    display: block;
    color: inherit;
    &-underline-block {
        > * {
            margin-bottom: 0;
            padding-bottom: 0;
        }
        margin-bottom: 1rem;
        border-bottom: 1px solid $green-dark;
    }
    &.centered {
        display: block;
        width: 100%;
        text-align: center;
    }

    &.alternative {
        > * {
            font-family: $font-family-bold;
        }
    }

    &.contained {
        @apply mx-auto lg:w-1/2;
    }
}
